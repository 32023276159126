@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
p {
  margin-bottom: 0.5vw;
}
@font-face {
  font-family: "TiemposHeadline";
  src: local("TiemposHeadline"),
    url("../assets/fonts/TiemposText-Regular.woff") format("woff");
}

@font-face {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../assets/fonts/CircularStdBook.woff") format("woff");
}

@font-face {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../assets/fonts/CircularStd-Medium.woff") format("woff");
}

@font-face {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../assets/fonts/CircularStd-Bold.woff") format("woff");
} */

.about-top {
  /* background-image: linear-gradient(
    rgb(179, 94, 94, 0),
    rgb(253, 253, 253, 100)
  ), url("../assets/images/banner2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  background: linear-gradient(180deg, #ffffff 0%, #dffff3 100%);
  padding-top: 0;
}

.about-full {
  display: grid;
  grid-template-columns: 1fr 550px;
  grid-gap: 89px;
  align-items: center;
}

.about-full h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  color: #33ca8f;
  text-align: left;
  text-transform: uppercase;
  margin: 0 0 12px 0;
  font-family: "Poppins", sans-serif;
  padding: 0;
}

.about-full h2 {
  font-weight: 600;
  font-size: 3em;
  line-height: 58px;
  color: #151852;
  margin: 0 0 30px 0;
  font-family: "Poppins", sans-serif;
  padding: 0;
}

.about-full p {
  font-size: 1em;
  line-height: 26px;
  color: #6f708a;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.information {
  padding: 90px 10px 0;
}

.information h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #33ca8f;
  margin: 0 0 12px 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.information h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 2.25em;
  line-height: 44px;
  color: #151852;
  max-width: 650px;
  text-align: center;
  margin: 0 auto 42px;
  font-family: "Poppins", sans-serif;
}

.award-blovk-full {
  display: grid;
  grid-template-columns: 515px 1fr;
  grid-gap: 56px;
  align-items: center;
}

.award-blovk-full p {
  font-size: 0.875em;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--solid_400);
  margin: 0 0 21px 0;
}

.award-blovk-full ul {
  margin: 0;
  padding: 9px 0 0;
}

.award-blovk-full ul li {
  margin: 0 0 18px 0;
  padding: 0 0 0 34px;
  display: flex;
  align-items: center;
  list-style: none;
  font-weight: 500;
  font-size: 1em;
  line-height: 24px;
  color: #151852;
  font-family: "Poppins", sans-serif;
  background-image: url("../assets/images/icon-checkmark.png");
  background-repeat: no-repeat;
  background-position: 0 50%;
}

.award-blovk-full ul li:last-child {
  margin: 0;
}

.fbnIntro {
  top: 28%;
  /* top: 30.5%; */
  /* position: absolute; */
}

.fbnIntro-home {
  padding: 0% 25% 0% 12% !important;
  width: 80%;
}

.fbnIntro-about {
  width: 100%;
  padding: 150px 0 160px 0 !important;
  position: unset !important;
}

.adjustwidth {
  width: 100%;
  text-align: justify;
}
.fbnIntro p:nth-child(1) {
  font-size: 3.5vw;
}
.introHeadline {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.875em;
  line-height: 38px;
  text-transform: uppercase;

  color: #55a5e4;
}

.introText {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25em !important;
  line-height: 44px;
  /* or 122% */

  color: #151852;
}

.introText2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.313em;
  line-height: 33px;
  /* or 157% */

  color: #505050;
}

.card {
  border: none;
}

.fbn-logo {
  height: 44px;
  width: 260px;
  margin-top: 10px;
  margin-left: 10px;
}

.page-1 {
  position: relative;
}

.left-ellipse {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.right-ellipse {
  position: absolute;
  bottom: 0px;
  left: 30px;
  opacity: 0.5;
}

.main-card {
  height: 80%;
  width: 720;
  max-width: 95vw;
}

.doctor-img {
  height: 180;
  width: 180;
  border-radius: 24px;
  margin-top: 35px;
}

.doctor-name-card {
  font-family: "Tiempos Headline" !important;
  font-style: normal;
  font-weight: bold;
  font-size: 2.625em !important;
  line-height: 46px;
  color: #151852;
  margin-top: 65px;
  margin-left: -10px;
}

.doctor-accreditations {
  font-family: "Tiempos Headline";
  font-style: normal;
  font-weight: bold;
  font-size: 1.375em;
  line-height: 46px;
  color: #33ca8f;
  margin-left: -7px;
  margin-top: 5px;
}

.doctor-bio {
  width: 640px;
  height: 210px;
  z-index: 3;

  margin-left: 18px;
  margin-top: 20px;

  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 300;
  font-size: 1.125em;
  line-height: 26px;

  text-align: left;

  color: #50597c;
}

.gradient {
  /* background-image: url("../assets/images/banner2.png");  */
  position: absolute;
  background-image: linear-gradient(
    rgb(179, 94, 94, 0),
    rgb(253, 253, 253, 100)
  );
  width: 100%;
  height: 100%;
  /* height: 100vh; */
  background-size: cover;
  color: white;
}

.info-title {
  color: #151852;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.info-text {
  /* position: absolute; */
  /* left: 8.26%;
  right: 51.67%;
  top: 67%;
  bottom: 27.01%; */

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.313em;
  line-height: 33px;
  /* or 157% */

  color: #505050;
}

.board {
  width: 100%;
  padding: 72px 0;
  background: #4cf7b7;
}

@media only screen and (max-width: 600px) {
  .board-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* .board-container {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.board_title {
  font-weight: 600;
  font-size: 2.25em;
  line-height: 36px;
  text-align: center;
  color: #151852;
  margin: 0 0 48px 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.col-sm {
  margin-bottom: 20px;
  bottom: 0 !important;
}

.doctor-name {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 31px;

  color: #151852;
}

.bio {
  margin-bottom: 0;
  color: #33ca8f;
  display: inline;
  /* bottom: 0 !important; */
}
.bio-btn {
  border: none;
  background-color: #eff9ff;
  /* background-color: white; */
  color: none;
  /* width: 358px; */
  width: 100%;
  /* bottom: 0 !important; */
}

* {
  box-sizing: border-box;
}
.two {
  width: 30em;
  max-width: 100%;
}
.two p {
  display: inline-block;
  max-width: 100%;
}

.logo-cfpc {
  border-radius: 6.5px;
  width: 29%;
  height: 23%;

  background: url("../assets/images/logo-cfpc.svg");
}

.logo-coach {
  border-radius: 6.5px;
  width: 28%;
  height: 20%;

  background: url("../assets/images/logo-coach.svg");
}

.logo-mhcc {
  /* border: 1px solid #d4d4d4;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.0780157); */
  border-radius: 6.5px;
  width: 28%;
  height: 16%;
  background: url("../assets/images/logo-mhcc 1.svg");
}

/* .logo-inner {
  padding-left: 22px;
  padding-right: 8px;
  padding-top: 43px;
  padding-bottom: 37px;
}
.logo-outer {
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.0780157);
} */

.demo-row {
  display: grid;
  grid-template-columns: 1fr 551px;
  align-items: center;
  grid-gap: 65px;
}

.demo-row h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  color: var(--green);
  font-style: normal;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  margin: 0 0 18px 0;
  text-align: left;
}

.demo-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  font-size: 2.25em !important;
  line-height: 36px;
  font-family: "Poppins", sans-serif;
  color: var(--dark) !important;
  margin: 0 0 30px;
  text-align: left;
}

.demo-description {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--solid_400);
  text-align: left;
  margin: 0 0 25px 0;
}

.demo {
  text-align: center;
  display: flex;
  align-items: center;
  padding: 90px 10px;
}

/* .demo-container {
  height: 600px;
} */
.demo-input-multi-select {
  width: 100%;
  height: 34px;
}

.contact-info {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 18px;
  color: #151852;
  text-align: left;
  margin: 0 0 18px 0;
}

.contact-info.last {
  margin: 0;
}

.contact-info.mail-cnt {
  padding-top: 11px;
}

.contact-info a {
  display: flex;
  align-items: center;
}
.demo-row  .contact-info a, .demo-row .contact-info.mail-cnt a{
  display: block !important;
  width: fit-content !important;
}

.col-right form {
  background: linear-gradient(180deg, #33ca8f 0%, #0bb8c8 100%);
  border: 1px dashed #33ca8f;
  box-sizing: border-box;
  border-radius: 302px 302px 0px 302px;
  padding: 24px 25px 0;
  max-height: 551px;
}

.col-right .form-box {
  background: #ffffff;
  border: 1px solid #4cf7b7;
  box-sizing: border-box;
  box-shadow: 0px 48px 60px rgba(119, 118, 156, 0.08);
  border-radius: 30px;
  padding: 30px;
}

.col-right .form-box .two-field {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 12px;
  text-align: left;
  margin-bottom: 18px;
}

.col-right .form-box .two-field label {
  text-align: left;
  font-size: 0.875em;
  line-height: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #151852;
  margin: 0 0 10px 0;
}

.col-right .form-box .two-field label em {
  color: #33ca8f;
  font-style: normal;
}

.col-right .form-box .input-field {
  text-align: left;
}

.col-right .form-box .input-field label {
  text-align: left;
  font-size: 0.875em;
  line-height: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #151852;
  margin: 0 0 8px 0;
}

.demo-input-message {
  background: #ffffff;
  border: 1px solid #4cf7b7;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 72px;
  height: auto !important;
  resize: none;
}

.about-submit-btn {
  margin-top: 24px;
}

.about-submit-btn button {
  background: linear-gradient(180deg, #33ca8f 0%, #0bb8c8 100%);
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.125em;
  margin: 0;
  padding: 0 !important;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  height: 60px;
  width: 100%;
  display: block;
  border: 0;
}

.about-submit-btn button:hover {
  background: #151852;
  color: #fff;
}

.icon-checkmark {
  padding-right: 16px;
}

.information-container {
  margin-top: 170px;
  margin-bottom: 100px;
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: #00a0c6;
  text-decoration: none;
  cursor: pointer;
}

.contact-icon {
  width: 36px;
  margin-right: 12px;
}

.close-btn-advisory-card {
  position: absolute;
  width: 114px;
  height: 48px;
  right: 36px;
  bottom: 36px;

  background: linear-gradient(180deg, #33ca8f 0%, #0bb8c8 100%);
  box-shadow: 0px 8px 18px rgba(51, 202, 143, 0.18);
  border-radius: 4px;

  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.form-control {
  background: #ffffff;
  border: 1px solid #4cf7b7;
  box-sizing: border-box;
  border-radius: 4px;
  height: 54px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 0.75em;
  line-height: 14px;
  color: #6f708a;
}

.submit-btn {
  background-color: #33ca8f;
  color: white;
  border-radius: 8px;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-left: 57.44px;
  padding-right: 57.44px;

  /* align-items: 1000px !important; */
}

.footer {
  /* width: 100%; */
  /* position: fixed; */
  padding: 0;
}
.board-container {
  text-align: center;
}
@media screen and (max-width: 820px) {
  .fbnIntro {
    width: 100%;
    padding: 0% 12% 0% 12%;
  }
  .adjustwidth {
    width: 100%;
  }
  .aboutus-content-p {
    font-size: 0.875em;
  }
  .timeline {
    position: relative;
  }
  .board {
    position: relative;
  }
  #border-line {
    border: 0;
  }
  .main-card {
    width: auto;
  }
  .fbn-logo {
    width: 250px;
    margin-left: 0;
  }
  .doctor-bio {
    margin-right: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* .timeline {
    margin-top: 700px !important;
  } */
  .row {
    margin-right: unset;
    margin-left: unset;
  }
  .demo-row {
    flex-direction: column;
  }
  .demo-title {
    font-size: 1.875em;
    text-align: center;
  }
  .demo-description {
    font-size: 1.125em;
    line-height: 26px;
    /* text-align: center; */
    width: 100%;
  }
  .contact-icon {
    width: 50px;
  }
  .contact-info {
    font-size: 1.125em;
  }
  .demo-input-message {
    height: 65px;
  }
  .submit-btn {
    padding: 0.2rem 0.8rem;
    margin-top: unset;
  }
  .about-submit-btn {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 640px) {
  .demo-description {
    font-size: 1em;
    line-height: 26px;
    margin: 0 0 15px 0;
  }
  .contact-info {
    font-size: 1em;
    line-height: 26px;
  }
  .contact-icon {
    width: 45px;
    padding: unset;
    padding-right: 10px;
  }
}

.sliderTray___-vHFQ {
  display: flex !important;
  align-items: center;
}

.slideHorizontal___1NzNV {
  padding: 0 9px !important;
}

.slide___3-Nqo {
  height: auto !important;
}

.slide___3-Nqo img {
  width: 100%;
  border-radius: 12px !important;
  overflow: hidden;
  position: relative;
  height: auto !important;
  max-height: 260px;
}
@media screen and (max-width:1024px) {
  .slide___3-Nqo img{
    object-fit: cover;
    height: auto;
  }
}
.team-wrapper {
  position: relative;
}

.team-data {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 18px;
}

.team-data p {
  font-weight: 600;
  font-size: 1.25em;
  font-family: "Poppins", sans-serif;
  line-height: 18px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 0;
}

.team-data button h6 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  color: #33ca8f;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.team-data button h6:hover {
  text-decoration: underline;
}

.team-data button {
  border: 0;
  margin: 0;
  padding: 0;
  background: none;
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  color: #33ca8f;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}

.img-block {
  position: relative;
  border-radius: 12px !important;
  overflow: hidden;
}

.img-block:after {
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 59.97%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.slideInner___2mfX9 {
  position: relative !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.timeline-content {
  padding: 90px 0;
}

.timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 67px 0 0;
}

.timeline-content h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  color: #0cb9c7;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 12px 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.timeline-content h2 {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 2.25em;
  line-height: 44px;
  color: #151852;
  text-align: center;
  margin: 0 0 40px 0;
  padding: 0;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 12px;
  background: #029eab;
  mix-blend-mode: multiply;
  border-radius: 100px;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 12%);
  outline: 4px solid #fff;
}

.timeline-container {
  padding: 0;
  position: relative;
  background: inherit;
  width: 50%;
}

.timeline-container.left {
  left: 0;
  text-align: right;
}

.timeline-container.right {
  left: 50%;
}

.timeline-container::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  top: 1px;
  right: 63px;
  background: #0cb9c7;
  border-radius: 100%;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 12%);
  outline: 6px solid #fff;
}

.timeline-container.right::after {
  left: 63px;
}

.timeline-container .content::before {
  content: "";
  position: absolute;
  width: 77px;
  height: 5px;
  top: 7px;
  right: 0;
  background: linear-gradient(270deg, #029eab 8.5%, #0cb9c7 88.97%);
  border-radius: 3px;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 12%);
  z-index: 1;
}

.timeline-container.right .content::before {
  left: 0;
  background: linear-gradient(270deg, #029eab 8.5%, #0cb9c7 88.97%);
  border-radius: 3px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.timeline-container .date {
  font-weight: 600;
  font-size: 1.5em;
  font-family: "Poppins", sans-serif;
  line-height: 24px;
  color: #0cb9c7;
  margin: 0 0 10px 0;
  padding: 0;
}

.timeline-container .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #f6d155;
  border: 2px solid #006e51;
  border-radius: 40px;
  text-align: center;
  font-size: 1.125em;
  color: #006e51;
  z-index: 1;
}

.timeline-container.left .icon {
  right: 56px;
}

.timeline-container.right .icon {
  left: 56px;
}

.timeline-container .content {
  position: relative;
}

.timeline-container.left .content {
  padding: 0 100px 0 150px;
}

.timeline-container.right .content {
  padding: 0 150px 0 100px;
}

.timeline-container .content h2 {
  margin: 0 0 10px 0;
  font-size: 1.125em;
  font-weight: normal;
  color: #006e51;
}

.timeline-container .content p {
  font-size: 1em;
  line-height: 24px;
  text-align: right;
  color: #151852;
  font-weight: 400;
  margin: 0 0 19px 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.timeline-container .content p:last-child {
  margin: 0;
}

.timeline-container.right .content p {
  text-align: left;
}

@media (max-width: 767.98px) {
  .timeline::after {
    left: 10px;
  }

  .timeline-container {
    width: 100%;
    padding-left: 10px;
    padding-right: 0;
  }

  .timeline-container.right {
    left: 0%;
  }

  .timeline-container.left::after,
  .timeline-container.right::after {
    left: 75px;
  }

  .timeline-container.left::before,
  .timeline-container.right::before {
    left: 100px;
    border-color: transparent #006e51 transparent transparent;
  }

  .timeline-container.left .date,
  .timeline-container.right .date {
    right: auto;
    left: 15px;
  }

  .timeline-container.left .icon,
  .timeline-container.right .icon {
    right: auto;
    left: 146px;
  }

  .timeline-container.left .content,
  .timeline-container.right .content {
    padding: 30px 30px 30px 90px;
    border-radius: 500px 0 0 500px;
  }
}

@media (max-width: 1024px) {
  .about-full {
    grid-template-columns: 1fr 1fr;
  }

  .timeline-container.left .content {
    padding: 0 100px 0 50px;
  }

  .timeline-container.right .content {
    padding: 0 50px 0 100px;
  }
}

@media (max-width: 992px) {
  .about-full {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    align-items: center;
  }

  .award-blovk-full {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    align-items: center;
  }

  .timeline-container.left .content {
    padding: 0 100px 0 0;
  }

  .timeline-container.right .content {
    padding: 0 0 0 100px;
  }

  .demo-row {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 40px;
  }
}

@media (max-width: 767px) {
  .about-full {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    align-items: center;
  }

  .fbnIntro-about {
    width: 100%;
    padding: 30px 10px !important;
    position: unset !important;
  }

  .col-right form {
    padding: 0;
    background: none;
    border: 0;
  }

  .col-right .form-box {
    padding: 20px;
  }

  .col-right .form-box .two-field {
    display: grid;
    grid-template-columns: 1fr;
  }

  .col-right form {
    max-height: inherit;
  }
}

.feeling-about-section {
  clear: both;
  padding: 90px 0 0;
  text-align: center;
}

.feeling-about-section h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1em;
  text-align: center;
  color: #33ca8f;
  margin: 0 0 10px 0;
  text-transform: uppercase;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.feeling-about-section h2 {
  font-weight: 600;
  font-size: 2.25em;
  line-height: 48px;
  text-align: center;
  color: #151852;
  font-family: "Poppins", sans-serif;
  margin: 0 auto;
  padding: 0 0 24px 0;
  max-width: 930px;
}

.feeling-about-section p {
  font-size: 1em;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #6f708a;
  margin: 0 auto;
  max-width: 930px;
  padding: 0 0 22px 0;
}

.user-result-wrpper {
  clear: both;
  padding: 0 0 90px 0;
}

.user-result-wrpper .full {
  display: grid;
  grid-template-columns: 463px 1fr;
  grid-gap: 60px;
  align-items: center;
}
.user-result-wrpper .card-body ul {
  display: block !important;
}

.user-result-wrpper h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #33ca8f;
  margin: 0 0 12px 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.user-result-wrpper h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 2.25em;
  line-height: 44px;
  color: #151852;
  text-align: center;
  margin: 0 auto 48px;
  font-family: "Poppins", sans-serif;
}

.user-result-wrpper .card {
  background: #ffffff;
  border: 1px solid #d9efe7 !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  cursor: pointer;
  margin: 0 0 8px 0;
  padding: 0;
}

.user-result-wrpper .card h2.collapsed {
  text-align: left;
  font-weight: 500;
  font-size: 1em;
  line-height: normal;
  color: #000;
  padding: 15px 35px 15px 24px;
  font-family: "Poppins", sans-serif;
  margin: 0;
  background-image: url("../assets/images/arrow_v1.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 96% 50%;
}

.user-result-wrpper .card h2 {
  font-weight: 600;
  font-size: 1em;
  line-height: normal;
  color: #33ca8f;
  padding: 15px 35px 15px 24px;
  margin: 0;
  text-align: left;
  font-family: "Poppins", sans-serif;
  background-image: url("../assets/images/arrow_v2.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 96% 50%;
}

.user-result-wrpper .card-body {
  padding: 0 24px 24px;
}

.user-result-wrpper ul {
  padding: 4px 24px 0 24px !important;
  margin: 0;
}

.user-result-wrpper ul li {
  font-size: 0.875em !important;
  line-height: 20px !important;
  color: #50597c !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  margin: 0 0 12px 0 !important;
  padding: 0 !important;
  list-style: outside !important;
}

.user-result-wrpper ul li:last-child {
  margin: 0;
}

@media (max-width: 1024px) {
  .user-result-wrpper .full {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .user-result-wrpper .full {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    align-items: center;
  }

  .user-result-wrpper .full img {
    width: 60% !important;
    margin: 0 auto;
    display: block;
    float: none !important;
  }
}

@media (max-width: 767px) {
  .about-full h2 {
    font-size: 1.875em !important;
    line-height: 40px;
    color: #151852;
    margin: 0 0 10px 0;
  }

  .about-full {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    align-items: center;
  }

  .feeling-about-section {
    clear: both;
    padding: 40px 10px 0;
    text-align: center;
  }

  .feeling-about-section h2,
  .timeline-content h2,
  .user-result-wrpper h2,
  .information h2,
  .board_title {
    font-size: 1.625em;
    line-height: 32px;
    margin: 0 0 30px 0;
    padding: 0;
  }

  .demo-title {
    font-size: 1.625em !important;
    line-height: 32px;
    margin: 0 0 30px 0;
    padding: 0;
  }

  .feeling-about-section p {
    padding: 0 0 15px 0;
  }

  .timeline {
    padding: 30px 0 0;
  }

  .timeline-content {
    padding: 40px 10px;
  }

  .timeline-container .content::before {
    left: 0;
  }

  .timeline-container.left .content::before,
  .timeline-container.right .content::before {
    left: 0;
    background: linear-gradient(270deg, #029eab 8.5%, #0cb9c7 88.97%);
    border-radius: 3px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .timeline-container.left .content {
    padding: 0 0 0 100px;
    text-align: left;
  }

  .timeline-container .content p {
    text-align: left;
  }

  .timeline-container {
    margin-bottom: 25px;
  }

  .user-result-wrpper {
    clear: both;
    padding: 0 10px 40px;
  }

  .demo-row h4 {
    text-align: center;
    margin: 0 0 12px 0;
  }

  .board {
    padding: 40px 10px;
  }

  .demo {
    padding: 40px 10px;
  }

  .user-result-wrpper .full {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    align-items: center;
  }

  .user-result-wrpper .full img {
    width: 80% !important;
    margin: 0 auto;
    display: block;
    float: none !important;
  }
}
@media screen and (max-width:768px){
  .main-info-card{
    width: 100%;
    padding: 20px 0 30px;
    max-width: 100vw;
  }
}
.advisory-card-btn{
  position: relative;
  margin-left: auto;
  margin-top: 20px;
  right: auto;
  bottom: auto;
  margin-right: 20px;
}
@media screen and (max-width:440px) {
  .doctor-img{
    height: auto;
    margin-top:66px;
  }
  .doctor-name-card{
    font-size:1.8rem !important;
    line-height:35px !important;
  }
  .doctor-accreditations{
    font-size: 1rem!important;
    line-height:20px;
  }
  .name-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* modal hgeight issue fixing */
@media screen and (max-width:1600px){
  .doctor-bio{
    height:25vh;
  }
  .main-card{
    height:80%;
  }
}
@media screen and (max-width:1280px){
  .doctor-bio{
    height:25vh;
  }
  .main-card{
    height:80% !important
  }
}
@media screen and (max-width:991px){
  .main-card{
    height:80% !important;
  }
}
@media screen and (max-width: 786px){
  .main-card {
    height: 80% !important;
  }
}
@media screen and (max-width: 576px){
  .main-card {
    height: 80% !important;
  }
}
@media screen and (max-width: 412px){
  .main-card {
    height: 80% !important;
  }
}

@media screen and (max-width: 375px){
  .main-card {
    height: 90% !important;
  }
}

