.privacy-policy-text {
  border: 2px solid grey;
  width: 80%;
  padding: 50px;
  align-items: center;
  border-radius: 10px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
  background-color: #fff;
}

.title {
  color: black;
}
