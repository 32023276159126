/* ************************************************************ */
.leftModal {
  margin: 0;
  bottom: 0;
  top: 0;
  position: fixed;
  min-width: 54vw;
}
/*Assessment History sidebar animate open from the left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}
.modal.left.fade.show .modal-dialog {
  left: 0;
}
/* ************************************************************ */
.fa-check-circle {
  color: #33ca8f;
}
.covid-19_alert {
  background-color: #1a3b41;
}
.covid-19_alert p {
  color: #b4d0d5;
}
.covid-19_alert button span {
  color: #dadada;
}

.navbar-nav li {
  padding: 0rem 1rem;
  margin: auto;
}
.navbar-nav {
  font-size: medium !important;
}
.lightBg {
  background-color: #ffffff;
}
.topCard {
  padding: 5%;
}
.bg li {
  background-color: #e7f6f6;
  /* margin: 15px; */
  /* border-radius: 15px; */
}
.symptomesText {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0.17px;
  color: #151852;
}
.summaryAccordionCards {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
}
.summaryAccordionCards li:first-child {
  padding-top: 1.75rem;
}
.summaryAccordionCards li:last-child {
  padding-bottom: 1.75rem;
}
.description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #151852;
}
.tipsText,
.therapyText {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  letter-spacing: 0.15px;

  color: #151852;
}
.medicationHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  letter-spacing: -0.18px;

  color: #8d8ea5;
}
.medicationText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  letter-spacing: -0.18px;

  color: #151852;
}
.accordionInnerBox {
  margin: -0.3em;
  margin-top: -5px;
  /* margin-bottom: 5px; */
  background: #fafafa;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0.3rem 0.5rem 1.8rem 0.5rem !important;
}
.listStyle {
  border-radius: 15px;
}
.listStyle li:nth-child(even) {
  margin: 5px;
  background-color: #e7f5f7;
  border-radius: 15px;
}
.listStyle li {
  margin: 5px;
  background-color: #f7f7f7;
  border-radius: 15px;
}
.medication li:nth-child(even) {
  background-color: #f8fcff;
}
.medication li {
  background-color: #e1f4f4;
}
.medication {
  border-radius: 15px;
}
.papMedicationHeader .col-3 {
  color: #8d8ea5;
  font-size: 21;
}
.csHeading {
  font-size: 16;
  color: #747587;
}
.csUl li:first-child {
  padding-top: 1.7rem !important;
}
.csUl li:last-child {
  padding-bottom: 1.7rem !important;
}
.csUl li {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 15px;
  padding-top: 1.5rem;
  /* padding-bottom: 1.3rem; */
}
.medicationAdvice p {
  font-size: 18;
}
.papListDesc li {
  font-size: 18;
  columns: #151852;
}
.talkTherapy p {
  font-size: 18;
  color: #151852;
}
.papBottomCardsDetail p {
  font-size: 16;
  color: #151852;
}
.papBottomCardsDetail {
  border-color: #d7d7d7;
}
.papMedicationContent .list-group-item .row .col-3 {
  font-size: 18;
  color: #151852;
}
.radius {
  border-radius: 15px !important;
}
/* .SummaryMainCard,
.SummaryMainCard .card {
  border-radius: 10px;
} */
/* .SummaryMainCard .card-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
} */
.summaryLifeFunctionProgress {
  background-color: #e7e7e7;
}
.summaryLifeFunctionProgress .progress-bar {
  background-color: #6dc4c9 !important;
}
.lfProgressWrpr span {
  color: #8f90a6;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.16px;
}
.lfhhsummaryText {
  color: #151852;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.2px;
}
.lfhhsummaryTextAns {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.18px;
  color: #8f90a6;
}
.SummaryMainCardHeader {
  background-color: #e1f4f4;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.SummaryMainCard {
  border-radius: 10px;
  border-radius: 10px;
}
.passwordRow {
  margin-left: -9px;
}
.customContainer {
  width: 100%;
  margin: auto;
  max-width: 80%;
  height: 100%;
}
.main {
  background-image: url("../assets/images/Vector-Smart-Object1.png");
  height: auto;
  font-family: "CircularStdBook", Arial, Helvetica, sans-serif;
  background-repeat: no-repeat;
  background-size: 100%;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  /* line-height: 55px; */
  letter-spacing: -0.24px;
  color: #f3763e;
}
.papTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.24px;
  color: #f3763e;
}
.papCardsBg li {
  background-color: #e7f6f6 !important;
}
.papCardsBg .row {
  background-color: #e7f6f6 !important;
}

.SummaryButton {
  background-color: #e1f4f4;
  color: #5a5c7c;
  padding: 15px 20px;
  width: 100%;
  border-radius: 10px;
}
.SummaryButton i {
  color: #72738d;
}
.bottomCardsTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  letter-spacing: -0.35px;
  color: #151852;
}
.hhlfTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;

  letter-spacing: -0.3px;

  color: #151852;
}
.bgColorCard-header {
  background-color: #6dc4c9 !important;
  color: #ffffff !important;
  transition: background-color 0.5s;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.round {
  border-radius: 15px !important;
}
.notRound {
  border-radius: 0px !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.summaryMainAccordionBackground {
  background-color: #f7f7f7;
  color: #151852 !important;
  border: 0;
}
.noneBorder {
  border: none;
}
.mwTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  letter-spacing: -0.18px;

  color: #151852;
}
.mwText {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #151852;
}
.accoMainSpan {
  font-style: normal;
  font-weight: 500;
  font-size: 18;
  letter-spacing: -0.18px;
}
.medicationMobileTablet span {
  font-size: 12px !important;
}

/* PERSONAL ACTION PLAN */

.papCard {
  padding-left: 7.5%;
  padding-right: 7.5%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%;
}
.papBriefText {
  font-size: 18px;
  color: #151852;
}
.papBriefTextWrpr {
  padding-right: 10% !important;
}
.papCard .card-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.papRiskLevel {
  font-size: 19px;
  color: #151852;
}
.supportResourcesCard {
  margin-top: 50px;
  margin-bottom: 100px;
  border-radius: 15px;
  background-color: var(--white_200);
}

.flexbox-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
}
.resource-card {
  width: 50%;
  /* flex:1; */
}
.papCard-header {
  background-color: #e1f4f4;
}
.papHeadingDisorder {
  color: #1b1e62;
  font-size: 28;
}
.papHeadingScore,
.papHeadingRisk {
  color: #151852;
  font-size: 22;
}

.bgImage {
  /* background-image: url("../assets/images/Vector-Smart-Object1.png"); */
  background-color: #a2dbdb;
  /* height: 175; */
  background-repeat: no-repeat;
  background-size: 100%;
  font-family: "CircularStdBook", Arial, Helvetica, sans-serif;
  color: #151852;
}
.navbar-container{
  /* padding-top: 1rem;
  padding-bottom: 1rem; */
  padding: 35px 0;
}
.navbar-summary-page {
  max-width: 90em;
  padding: 0 2em;
  margin: 0 auto 0 auto;
  
}
.navbar-assessment-page {
  max-width: 90em;
  margin: 0 auto 0 auto;
}
.navbar-assessment-page > .navbar {
  padding: .5rem 0rem !important;
}
.navbar-summary-page > .navbar {
  padding: .5rem 0rem !important;
}
.talkTherapyCards {
  width: 80;
  height: 80;
  border-radius: 50px !important;
  background-color: #d6d6d6 !important;
}

@media only screen and (max-width: 600px) {
  .medicationDesktop {
    display: none;
  }
  .row,
  .singinContainer {
    margin-top: 0px;
  }
  .SummaryButton {
    background-color: #e1f4f4;
    color: #5a5c7c;
    padding: 15px;
    width: 100%;
  }
  .SummaryMainCard {
    max-width: 100%;
    border: none;
    border-radius: 10px;
  }
  .cardBody {
    padding: 0rem;
  }
  .customContainer {
    width: 100%;
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
  }
  .customContainer {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .emailInput {
    width: 200px;
  }
  .rightEmailIcon,
  .wrongEmailIcon {
    margin-left: 0rem !important;
  }
  .removeImgMobile {
    display: none;
  }
  .forgorPass {
    text-align: left !important;
  }
  .topCard {
    padding: 0px;
    border: 0px;
  }
  .personIcon,
  .lockIcon {
    margin-left: 1rem !important;
    margin-right: 0.1rem !important;
  }
  .papCard,
  .supportResourcesCard {
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 3%;
    /* padding: 40px; */
  }

  .papCard-header {
    padding: 0.75rem 0rem;
    background-color: #e7f6f6;
  }
  .riskLevel {
    display: none;
  }

  .papFBNlogo {
    text-align: center !important;
    padding: 10px 0px;
  }
  .headerRow {
    padding: 0px !important;
  }
}
/* summary details */
.card.SummaryMainCard.my-5.summary-details {
  width: 100%;
}
.summary-details {
  padding: 56px 40px;
}
.summary-details h2 {
  color: #23255b;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 45px;
  margin-bottom: 40px;
}
.summary-details h3 {
  color: #23255b;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.summary-details .SummaryButton {
  background-color: #f1f1f3;
}
.summary-details small {
  font-size: 1rem;
  opacity: 0.8;
  color: #5a5c7c !important;
}
.accordion-title {
  font-size: 19px;
  color: #23255b;
}
.carddetail-question h4 {
  font-size: 17px;
  font-weight: bold;
  color: #444559;
}
.carddetail-question p {
  font-size: 16px;
  font-weight: 400;
  color: #7c7c88;
  line-height: 30px;
}
.carddetail-question span {
  font-size: 16px;
  font-weight: bold;
  color: #444559;
}

.chartWrapper {
  position: relative;
}
.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.chartAreaWrapper {
  width: 600px;
  overflow-x: scroll;
}

@media only screen and (max-width: 768px) {
  .papCard,
  .supportResourcesCard {
    padding-right: 1%;
    padding-left: 1%;
  }

  .papFBNlogo {
    text-align: center !important;
    padding: 10px 0px;
  }
}
@media only screen and (max-width: 1019px) {
  .removeImgMobile {
    display: none;
  }
  .fullCardWidthTablet {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medicationDesktop {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .main-container {
    max-width: 1440px;
    width: 90%;
    margin: auto;
  }
  .hideInSmall {
    justify-content: center !important;
  }
  .hideInSmall span {
    display: none;
  }
}
@media only screen and (min-width: 1020px) {
  .medicationMobileTablet {
    display: none;
  }
}
