* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  padding-top: 0;
}
p {
  margin-bottom: 0.5vw;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header--alt {
  background: rgb(223, 255, 243);
}
.header--alt .navbar-container, .custom-w {
  max-width: 1440px;
  width: 95% !important;
  flex-basis: 95% !important;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.navbar-container .navbar {
  padding: 0;
}
@media (max-width: 1200px) {
  .header--alt .navbar-container, footer .container, .custom-w {
    width: 100%;
    flex-basis: 100%;
  }
}

footer {
  padding: 30px 20px 20px;
}
// .footer-block {
//   display: flex;
//   justify-content: space-between;
//   .block {
//     min-width: 18%;
//   }
// }
.header--alt .btn.signinBtn, .gradient-bg {
  background: linear-gradient(to bottom, #33CA8F, #0BB8C8);
  color: #fff !important;
}
.cards-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 18px;
  padding-top: 24px;
  padding-bottom: 90px;
}


.masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}
main {
  font-family: "Poppins";
}
.masonry-grid_column {
  padding-left: 15px;
  background-clip: padding-box;
}

.masonry-grid_column > div {
  margin: 15px auto;
}

.rightside {
  position: absolute;
  top: 0px;
  left: 600px;
}

.leftside {
}


.resource-cards {
  width: 1300px;
  position: relative;
}

.btn {
  box-shadow: none;
  z-index: 9;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 1024px) {
  .cards-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px;
    padding-top: 24px;
    padding-bottom: 40px;
  }
 
}

@media (max-width: 767px) {
  .cards-row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 18px;
    padding-top: 24px;
    padding-bottom: 40px;
  }

  .icon-section {
    padding: 0;
  }

  .navbar-container {
    padding: 20px 0;
  }

}

.custom-tab-link {
  padding: 5px 10px 0px;
}

.custom-tab-link.active {
  color: #33ca8f !important;
}


@media (max-width:991px) {
  .nav-link.custom-tab-link{
    color: #151852 !important;
  }
  .nav-link.custom-tab-link.active {
    color: #33ca8f !important;
  }
}

