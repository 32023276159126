.top-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 18px 30px 0 30px;

  > div {
    margin-top: 0 !important;
    margin-bottom: 6px !important;
    margin-right: 6px !important;
  }
}

.summary-h3 {
  font-size: 1.5rem;
  margin: 1.5rem 0;
  padding: 0 30px;
}

.progress-chart {
  padding: 0 30px;
}

.stretch-description {
  flex: 1 0 50% !important;
  max-width: 100%  !important;
}

.modal {
  height:100%;
  margin:auto;
}

.therapy-education-links {
 text-decoration: underline;

}

.top-btn.hover {
  box-shadow: 0 2px 20px rgba(0,0,0,.1);
  transition: .3s;
  .arrow {
    opacity: 0;
    transition: .5s;
  }
  p:nth-of-type(1) {
    opacity: 0;
    visibility: hidden;
    transition: .5s;
  }
  p:nth-of-type(2) {
    opacity: 1;
    visibility: visible;
    transition: .5s;
  }
  p.number{
    opacity: 1 !important;
    visibility: visible !important;
    transition: .5s !important;
  }
}