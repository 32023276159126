.circle-margin {
  margin-left: 13px;
  margin-right: 4px;
  font-size: 0.76em;
}

.medication-table {
  h3 {
    font-size: 0.9em;
  }
  p {
    font-size: 0.9em;
  }
}

.h2-pap {
  font-weight: 600;
  font-size: 1.6em;
  color: #f3763e;
}

@media print {
  .no-print {
    display: none;
  }
}

.goal-content-wrapper {
  clear: both;
  padding: 0 30px 30px;
  .goal-content-inner {
    // border-top: 2px solid #EEEEEE;
    padding: 36px 0 0;
    h2 {
      font-size: 30px;
      line-height: 30px;
      color: #151852;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      margin: 0 0 24px 0;
    }
    p {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      color: #505050;
      margin: 0 0 24px 0;
      strong {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #151852;
      }
    }
  }
}

.full-divide-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: flex-start;
}

.full {
  display: grid;
  grid-template-columns: 250px 1fr;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px 0;
}

.full .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ul {
    margin: 0 24px 0 0;
    padding: 0;
    display: flex;
    align-items: center;
    grid-gap: 24px;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #151852;
    }
    li.complete {
      padding-left: 28px;
      border: 0;
      background: url("../assets/images/complete_icon.svg");
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 20px 20px;
      font-size: 16px;
      line-height: 24px;
      color: #505050;
      font-weight: 400;
    }
    li.in-progress {
      padding-left: 28px;
      border: 0;
      background: url("../assets/images/in_progress_icon.svg");
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 20px 20px;
      font-size: 16px;
      line-height: 24px;
      color: #505050;
      font-weight: 400;
    }
    li.pending {
      padding-left: 28px;
      border: 0;
      background: url("../assets/images/pending_icon.svg");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 0 50%;
      font-size: 16px;
      line-height: 24px;
      color: #505050;
      font-weight: 400;
    }
  }
}
.goal-content-wrapper{
  .full{
    .right{
      .add-btn{
        width: 38%;
      }
    }
  } 
}
.dropdown.symptom {
  background: #ffffff;
  border: 1px solid #33ca8f;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #33ca8f !important;
  transition: 0.3s;
  font-family: "Poppins", sans-serif;
  padding: 0;
  text-align: center;
  // width: 176px;
  height: 48px;
  line-height: 45px;
  .dropdown-toggle {
    padding: 0;
    &:hover {
      color: #33ca8f !important;
    }
  }
  .dropdown-menu.show {
    left: 0 !important;
    right: 0 !important;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.12);
    border: 0;
    border-radius: 0;
    margin: 3px 0 0;
    width: 220px;
    padding: 12px;
  }
}

.dropdown.symptom ul {
  display: block !important;
  margin: 0 !important;
  padding: 0 !important;
  li {
    margin: 0;
    padding: 12px 0;
    list-style: none;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #151852;
    font-weight: 400;
    justify-content: space-between;
    cursor: pointer;
    text-transform: capitalize;
    span {
      font-weight: 600;
      font-size: 12px;
    }
    &:hover {
      background: #e7f9f7;
      margin: 0 -12px;
      padding: 12px 12px;
    }
  }
}

.dropdown.symptom ul li.add-btn {
  padding: 0;
  margin: 12px 0 0;
}

.dropdown.symptom ul li.add-btn a {
  width: 56px;
  height: 24px;
}

.dropdown.symptom ul li.add-btn input {
  padding: 0 90px 0 12px;
  font-size: 12px;
  line-height: 14px;
  height: 36px;
}

.dropdown.symptom ul li.active {
  background: #e7f9f7;
  margin: 0 -12px;
  padding: 12px 12px;
  position: relative;
}

.dropdown.symptom ul li.active::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  background: #33ca8f;
  height: 100%;
  content: "";
}

.dropdown {
  background: linear-gradient(180deg, #33ca8f, #0bb8c8);
  color: #fff !important;
  transition: 0.3s;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 0;
  border-radius: 6px;
  border: 1px solid #4cf7b7;
  text-align: center;
  border: none;
}

.dropdown a:hover {
  color: #fff !important;
}

.dropdown .dropdown-toggle {
  display: inline-block;
  padding: 13px 22px;
  width: 100%;
}

.dropdown i {
  margin-left: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu a:hover {
  color: #33ca8f !important;
}

.search-container {
  position: relative;
}

.search-container input {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dddfe3;
  border-radius: 4px;
  height: 48px;
  padding: 10px 40px 10px 18px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Poppins", sans-serif;
  color: #979797;
}

.search-container button {
  margin: 0;
  padding: 0;
  border: 0;
  background: url("../assets/images/search_icon.png");
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.plan-box {
  background: #f2fafa;
  border-radius: 4px;
  padding: 18px;
  font-family: "Poppins", sans-serif;
  margin: 0 0 12px 0;
  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-transform: capitalize;
    color: #151852;
    margin: 0 0 18px 0;
    padding: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    clear: both;
    li {
      margin: 0 8px 5px 0;
      padding: 0;
      list-style: none;
      display: block;
      vertical-align: middle;
      input {
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 12px;
        line-height: 12px;
        text-transform: capitalize;
        color: #979797;
      }
    }
    li.add-btn {
      margin: 18px 0 0;
      padding: 0;
    }
  }
}

.plan-box {
  ul {
    margin: 0;
    padding: 0;
    li {
      margin: 0 0 12px 0;
    }
    li.complete {
      padding-left: 24px;
      border: 0;
      background: url("../assets/images/complete_icon.svg");
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 16px 16px;
      font-size: 16px;
      line-height: 24px;
      color: #505050;
      font-weight: 400;
    }
    li.in-progress {
      padding-left: 24px;
      border: 0;
      background: url("../assets/images/in_progress_icon.svg");
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 16px 16px;
      font-size: 16px;
      line-height: 24px;
      color: #505050;
      font-weight: 400;
    }
    li.pending {
      padding-left: 24px;
      border: 0;
      background: url("../assets/images/pending_icon.svg");
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 0 50%;
      font-size: 16px;
      line-height: 24px;
      color: #505050;
      font-weight: 400;
    }
  }
  &:last-of-type {
    margin: 0;
  }
}

.full-box {
  background: #ffffff;
  border: 1px solid #dcf0f0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px 18px 18px;
  margin: 0 0 12px 0;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      text-transform: capitalize;
      color: #33ca8f;
      font-family: "Poppins", sans-serif;
      margin: 0;
    }
    .date {
      font-size: 18px;
      line-height: 24px;
      text-align: right;
      text-transform: capitalize;
      color: #151852;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      background: url("../assets/images/arrow.svg");
      background-repeat: no-repeat;
      background-position: 100% 50%;
      padding-right: 20px;
    }
  }
}

.full-box p.cnt {
  font-size: 16px;
  line-height: 24px;
  color: #505050;
  margin: 18px 0 12px 0;
}

.button-section {
  padding: 6px 0 0;
}

.button-section button {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: #ec5f43;
  font-family: "Poppins", sans-serif;
  border-radius: 100px;
  width: 148px;
  height: 48px;
  &:hover {
    background: #000;
    color: #fff;
  }
}

.add-btn {
  position: relative;
}

.add-btn input {
  background: #ffffff;
  border: 1px solid #dddfe3;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  height: 42px;
  padding: 0 95px 0 18px;
  font-size: 14px;
  line-height: 14px;
  color: #979797;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.add-btn a {
  margin: 0;
  padding: 0;
  background: #33ca8f;
  border: 1px solid #33ca8f;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  width: 74px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 6px;
}
.remove-bg {
  background-image: none !important;
  z-index: 1;
  max-width: 90%;
}
.save-title-btn-padding {
  padding: 0 18px 18px 18px;
}
.title-input {
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #33ca8f;
  border: none;
  padding: 0;
}
.react-datepicker-wrapper {
  width: auto !important;
}
.collapse-icon {
  background-image: url("../assets/images/arrow_v2_black.svg");
  background-repeat: no-repeat;
  // background-position: 96% 50%;
  background-position: center right;
  position: absolute;
  right: 20;
  width: 100%;
  z-index: 0;
  height: 100%;
}
.collapse-icon.collapsed {
  background-image: url("../assets/images/arrow_v1_black.svg");
}
.btn-rect {
  margin: 0;
  padding: 0;
  background: #33ca8f;
  border: 1px solid #33ca8f;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  width: 74px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.btn-disabled {
  background-color: #acacac;
  border-color: #acacac;
  color: #000
}
.btn-rect span {
  padding: 1px 0 0 1px;
  border: 0;
  // background: url(/static/media/plus_icon.1cf2f7ed.svg);
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 0 50%;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.add-btn a span {
  padding: 1px 0 0 16px;
  border: 0;
  background: url("../assets/images/plus_icon.svg");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 0 50%;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.add-plan-section ul {
  margin: 0;
  padding: 0;
  display: block;
  li {
    margin: 0 4px 4px 0;
    padding: 8px 10px;
    float: left;
    list-style: none;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    color: #33ca8f;
    border: 1px solid #33ca8f;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
  }
}

.add-plan-section .button-section {
  clear: both;
  text-align: right;
  margin: 0 0 12px 0;
  padding: 20px 0 0;
}
.no-content-part .content-part p {
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  text-transform: capitalize;
  color: #151852;
  font-weight: 400;
  margin: 48px 0 36px 0;
}
.no-content-part .content-part .button-section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.image-part .no-content-image {
  width: 50%;
  height: auto;
}
.image-part {
  display: flex;
  justify-content: center;
}
.descriptionList{
  padding: 20px;
}
// accordion design
.goal-content-wrapper{
  .accordion-wrapper{
    margin-bottom: 35px;
    .card {
      background: #ffffff;
      border: 1px solid #d9efe7 !important;
      box-sizing: border-box;
      border-radius: 4px !important;
      margin: 0 0 8px 0;
      padding: 0;
      .card-head{
        cursor: pointer;
      }
      h2{
        text-align: left;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #33ca8f;
        padding: 24px 18px 18px 18px;
        font-family: "Poppins", sans-serif;
        margin: 0;
        background-image: url("../assets/images/arrow_v2_black.svg");
        background-repeat: no-repeat;
        background-position: 96% 50%;
        &.collapsed {
          background-image: url("../assets/images/arrow_v1_black.svg");
        }
        img{
          cursor: pointer;
        }
      }
      .card-body {
        padding: 0 18px 18px;
      }
      .chips-drodown-part{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .chips-part{
          display: flex;
          max-width: 80%;
          ul{
            list-style-type: none;
            display: flex;
            margin: 0;
            flex-wrap: wrap;
            li{
              border: 1px solid #33CA8F;
              border-radius: 100px;
              padding: 10px 24px;
              font-family: "Poppins", sans-serif;
              font-weight: 500;
              font-size: 10px;
              line-height: 10px;
              text-align: center;
              color: #33CA8F;
              margin: 0 4px 8px 0px;
            }
          }
          .chips-btn{
            border: 1px solid #5F6477;
            border-radius: 100px;
            padding: 3px;
            width: 30px;
            height: 30px;
            text-align: center;
            background-color: transparent;
          }
        }
        .date-time-dropdown{
          .dropdown{
            background: #E7F9F7;
            border-radius: 4px;
            height: 42px;
            a{
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
              text-transform: capitalize;
              color: #151852;
              &:hover{
                color: #212529 !important;
              }
            }
            .dropdown-menu{
              &.custom-menu{
                z-index: 900000000;
                transform: translate3d(0px, 40px, 0px);
                width: 100%;
                border-radius: 0;
                border: 1px solid rgb(226, 244, 242);
                box-shadow: 0px 0px 30px rgba(0,0,0,0.12);
                margin: 0px;
                padding: 0px;
                ul{
                  margin-bottom: 0;
                  li{
                    background-color: #fff;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    text-transform: capitalize;
                    color: #151852;
                    border: 0;
                    outline: 0;
                    padding: 12px 15px;
                    -webkit-transition: all 0.2s;
                    transition: all 0.2s;
                    width: 100%;
                    &:hover,&.active{
                      background-color: #e7f9f7;
                    }
                  }
                }
              }
            }
          }
        }
      }
      p.content{
        font-size: 16px;
        line-height: 24px;
        color: #505050;
        margin: 18px 0;
        font-family: "Poppins", sans-serif;
        img{
          cursor: pointer;
        }
      }
      .full-divide-block{
        font-family: "Poppins", sans-serif;
        .full-box{
          padding: 18px;
          background: #F2FAFA;
          border-radius: 4px;
          border: 1px solid #F2FAFA;
          .header-content{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            h5{
              font-weight: 600;
              font-size: 24px;
              line-height: 24px;
              text-transform: capitalize;
              color: #151852;
              font-family: "Poppins", sans-serif;
              margin-bottom: 0;
              max-width: 48%;
            }
            .right-content{
              display: flex;
              align-items: center;
              .plan,.coins{
                margin-right: 18px;
              }
              h6{
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                text-transform: capitalize;
                color: #151852;
                margin-bottom: 0;
              }
              .icon{
                margin-right: 6px;
              }
              button{
                background: #FFFFFF;
                border: 1px solid #DFFFF3;
                box-sizing: border-box;
                border-radius: 100px;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                color: #151852;
                width: 90px;
                height: 35px;
              }
            }
          }
          .event-log-part{
            margin: 26px 0;
            .goal-main-content{
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
              &:last-child{
                margin-bottom: 0;
              }
            }
            .switch-goal-part{
              display: flex;
              align-items: center;
              h6{
                font-size: 16px;
                line-height: 24px;
                color: #505050;
                margin-bottom: 0;
                &.complete-process{
                  text-decoration-line: line-through;
                }
              }
            }
            .calendar-part{
              display: flex;
              justify-content: flex-end;
              align-items: center;
              p{
                font-size: 12px;
                line-height: 24px;
                color: #151852;
                margin-bottom: 0;
                margin-right: 16px;
                text-transform: uppercase;
                width: 100px;
                text-align: right;
                display: flex;
                justify-content: space-between;
                &.date{
                  display: block;
                }
                .day-name:hover {
                  color: #33ca8f;
                }
                .day-name{
                  cursor: pointer;
                  &.active{
                    color: #33ca8f;
                  }
                }
              }
              .btn{
                width: 28px;
                height: 28px;
                left: 626px;
                top: 690px;
                background-color: #FFFFFF;
                border: 1px solid #DFFFF3;
                border-radius: 100px;
                margin-right: 8px;
                background-repeat: no-repeat;
                background-position: center;
                &:last-child{
                  margin-right: 0;
                }
                &:hover{
                  &.delete-button{
                    background-color:#EC5F43;
                    background-image: url("../assets/images/delete-icon-white.svg");
                  }
                  &.refresh-button{
                    background-color:#0BB8C8;;
                    background-image: url("../assets/images/refresh-white.svg");
                  }
                  &.calendar-button{
                    background-color: #909295;
                    background-image: url("../assets/images/calendar-white.svg");
                  }
                }
                &.delete-button{
                  background-image: url("../assets/images/delete-icon.svg");
                }
                &.refresh-button{
                  background-image: url("../assets/images/refresh-black.svg");
                }
                &.refresh-button.active{
                  background-color:#0BB8C8;;
                  background-image: url("../assets/images/refresh-white.svg");
                }
                &.calendar-button{
                  background-image: url("../assets/images/calendar.svg");
                }
              }
            }
          }
        }
      }
      .footer-btn{
        margin-top: 18px;
        .btn{
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          text-transform: uppercase;
          padding: 12px 18px;
          font-family: "Poppins", sans-serif;
          background: #FF2A00;
          color: white;
          border-radius: 100px;
          &:hover{
            background-color: #000;
          }
          &.outline-btn{
            background: #FFFFFF;
            border: 1px solid #33CA8F;
            margin-right: 8px;
            color: #33CA8F;
            &:hover{
              color: #000;
              border: 1px solid #000;
            }
          }
          &.filled-btn{
            background: #33CA8F;
            border: 1px solid #33CA8F;
            margin-right: 8px;
            color: #FFF;
            &:hover{
              border: 1px solid #FFF;
              background-color: #000;
            }
          }
        }
      }
    }
  }
}

.hidden-textarea {
  border: none;
  width: 90%;
}

.z-index-0 {
  z-index: 0 !important;
}

@media only screen and (max-width: 1024px) {
  .full-box .title .date {
    font-size: 14px;
  }

  .full-box .title h3 {
    font-size: 22px;
  }
  .goal-content-wrapper{
    .full{
      .right{
        ul{
          margin: 0 12px 0 0;
          padding: 0;
          display: flex;
          align-items: center;
          grid-gap: 12px;
        }
        .add-btn{
          width: 42%;
        }
      }
    } 
    .accordion-wrapper{
      .card {
        .full-divide-block{
          .full-box{
            .header-content{
              flex-wrap: wrap;
              h5{
                max-width: 100% !important;
              }
              .right-content{
                justify-content: flex-end;
                width: 100%;
                margin-top: 10px;
              }
            } 
            .event-log-part{
              .goal-main-content{
                flex-wrap: wrap;
                .switch-goal-part{
                  width: 100%;
                }
                .calendar-part{
                  width: 100%;
                  margin-top: 8px;
                }
              }
            } 
          }
        }
      }
    }   
  }
}

@media only screen and (max-width: 991px) {
  .goal-content-wrapper{
    .full{
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
    .accordion-wrapper{
      .card {
        .full-divide-block{
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 10px;
          align-items: flex-start;
          .full-box{
            .header-content{
              flex-wrap: nowrap;
              h5{
                max-width: 50% !important;
                width: 50%;
              }
              .right-content{
                justify-content: flex-end;
                width: 50%;
                margin-top: 0;
              }
            } 
            .event-log-part{
              .goal-main-content{
                flex-wrap: nowrap;
              }
            } 
          }
        }
      }
    }  
  }
  .no-content-part .content-part p {
    margin: 40px 0 28px 0;
  }
}
@media only screen and (max-width: 768px) {
  // .full-divide-block {
  //   display: grid;
  //   grid-template-columns: 1fr;
  //   grid-gap: 10px;
  //   align-items: flex-start;
  // }

  .full {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    grid-gap: 15px;
    margin: 0 0 24px 0;
  }

  .dropdown.symptom .dropdown-menu.show {
    right: 0 !important;
    transform: inherit !important;
    top: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .full-box .title {
    display: block;
  }

  .full-box .title .date {
    font-size: 14px;
    line-height: 20px;
  }

  .full-box p.cnt {
    font-size: 14px;
    line-height: 20px;
  }

  .plan-box ul li {
    margin: 0 0 6px 0;
    width: 100%;
  }

  .plan-box ul li input {
    width: 100%;
  }

  .goal-content-wrapper {
    padding: 0 15px 30px;
  }

  .goal-content-wrapper .goal-content-inner h2 {
    margin: 0 0 15px 0;
    font-size: 25px;
    line-height: 30px;
  }
  .goal-content-wrapper{
    .full{
      .right{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        ul{
          display: block;
          li{
            margin: 4px 0;
          }
        }
        .add-btn{
          width: 100%;
          margin-top: 15px;
        }
      }
    }
    .accordion-wrapper{
      .card{
        .chips-drodown-part{
          flex-wrap: wrap;
          .chips-part {
            max-width: 100%;
          }
          .date-time-dropdown{
            margin-top: 10px;
            width: 100%;
          }
        }
        .full-divide-block{
          .full-box{
           
            .header-content{
              flex-wrap: wrap;
              h5{
                max-width: 100% !important;
                width: 100%;
              }
              .right-content{
                justify-content: space-between;
                width: 100%;
                margin-top: 10px;
                .plan,.coins{
                  margin-right: 0;
                }
              }
            }
            .event-log-part{
              .goal-main-content{
                flex-wrap: wrap;
              }
            } 
          }
        }
      }
      
    }
  }  
  .goal-content-wrapper .goal-content-inner p,
  .goal-content-wrapper .goal-content-inner p strong {
    font-size: 14px;
    line-height: 24px;
  }

  .full-box {
    padding: 24px 15px 18px;
  }

  .full-box .title h3 {
    margin: 0 0 10px 0;
  }

  .plan-box h4 {
    font-size: 20px;
    line-height: 24px;
  }

  .plan-box {
    padding: 12px;
  }

  .plan-box ul li {
    font-size: 14px !important;
  }
  .no-content-part .content-part p {
    margin: 25px 0 18px 0;
    font-size: 18px;
  }
}

.description-hidden {
  transition: .3s;
  line-height: 1.5em;
  height: 12em;       /* height is 2x line-height, so two lines will display */
  overflow: hidden;
}
.show-more {
  // height: auto;
  // text-overflow: inherit;
  transition: 0.3s;
}

// three way toggle switch
.three-way-toggle{
  .toggle-container {
    display: flex;
    align-items: center;
    width: 60px;
    height: 18px;
    background: #FFFFFF;
    border: 1px solid #D9EFE7;
    border-radius: 100px;
    position: relative;
    margin-right: 8px;
  }
  
  input {
    display: none;
  }
  
  label {
    padding: 10px;
    width: 100%;
    cursor: pointer;
  }
  
  .round {
    position: absolute;
    top:-4px; left: 0; right: 0; bottom: 0;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    transition: all .3s ease;
  }
  
  .checkbox1:checked ~ .round {
    transform: translateX(0px);
    background: #EC5F43;
  }
  
  .checkbox2:checked ~ .round {
    transform: translateX(75%);
    background: #FFC133;
  }
  
  .checkbox3:checked ~ .round {
    transform: translateX(155%);
    background: #33CA8F;
  }
}

body{
  background : #fff;
  margin: 0;
}

.tab-container{
  background : transparent;
  margin: 0;
  padding: 0;
  max-height: 35px;
}

ul.tabs-custom{
  margin: 0;
  list-style-type : none;
  line-height : 35px;
  max-height: 35px;
  overflow: hidden;
  display: inline-block;
  padding-right: 20px;
  padding-left: 20px;
}

ul.tabs-custom > li.active{
  z-index: 2;
  background: #fff;
}

ul.tabs-custom > li:before{
  border-color : transparent #efefef transparent transparent;
  box-sizing: content-box !important;
}

ul.tabs-custom > li.active:before{
  border-color : transparent #fff transparent transparent;
  box-sizing: content-box !important;
}


ul.tabs-custom > li.active:after{
  border-color : transparent transparent transparent #fff;
  box-sizing: content-box !important;
}
ul.tabs-custom > li:after{
  border-color : transparent transparent transparent #efefef;
  box-sizing: content-box !important;
}

ul.tabs-custom > li{
  float : right;
  margin : 5px -10px 0;
  border-top-right-radius: 25px 170px;
  border-top-left-radius: 20px 90px;
  padding : 0 30px 0 25px;
  height: 170px;
  background: #ddd;
  position : relative;
  /* box-shadow: 0 10px 20px rgba(0,0,0,.5);*/
  max-width : 200px;
}

ul.tabs-custom > li > a{
  display: inline-block;
  max-width:100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #222;
}

ul.tabs-custom > li:before, ul.tabs-custom > li:after{
  content : '';
  background : transparent;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border-width: 10px;
  top: 0px;
  border-style : solid;
  position : absolute;
  box-sizing: content-box !important;
}

ul.tabs-custom > li:before{
  border-color : transparent #ddd transparent transparent;
  -webkit-transform : rotate(48deg);
  left: -23px;
  box-sizing: content-box !important;
}

ul.tabs-custom > li:after{
  border-color : transparent transparent transparent #ddd;
  -webkit-transform : rotate(-48deg);
  right: -17px;
  box-sizing: content-box !important;

}

/* Clear Fix took for HTML 5 Boilerlate*/

.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }