@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root {
  --green: #33ca8f;
  --dark: #151852;
  --blue: #23c0d3;
  --grey: #73758c;
  --grey_1100: #8a8b9d;
  --grey_1000: #9697aa;
  --grey_900: #a2a2ac;
  --grey_800: #b4b5bd;
  --grey_700: #b1b6b9;
  --grey_600: #dddddd;
  --grey_500: #e6e6e6;
  --grey_400: #efefef;
  --grey_300: #eeeeee;
  --grey_200: #efeff3;
  --grey_100: #f0f0f0;
  --white_400: #eeeef3;
  --white_300: #eaeaea;
  --white_200: #fafafa;
  --white_100: #ffffff;
  --black_750: #5d6868;
  --black_650: #434a4b;
  --black_550: #393c3c;
  --black: #000;
  --black_900: #1c2121;
  --black_800: #393c3c;
  --black_700: #5d6868;
  --cyan: #0cb8c9;
  --cyan_900: #1f90f2;
  --cyan_800: #0cb9c8;
  --cyan_700: #0cb9c7;
  --orange: #ec5f43;
  --orange_500: #EB5E40;
  --white: #ffffff;
  --white_400: #fff;
  --white_900: #a1a2af;
  --white_800: #999ba4;
  --solid_400: #6F708A;
  --light_green: #ECF8F8;
  --light_yellow: #FFFDF4;
  
}
.titleAsmnt {
  display: flex;
  color: #23255b;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 65px;
}
.navbar-collapse {
  display: flex;
}
.navbar-collapse ul {
  /* background-color: lightblue; */
  border-radius: 15px;
}

.heading {
  font-size: 18px;
  color: var(--dark);
}
.percent {
  font-size: 16px !important;
  color: var(--grey_1100);
}
.trueStat {
  border-radius: 18px !important;
  background-color: var(--white_100);
  color: var(--dark);
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--white_300);
  margin: 1rem 0rem;
  -webkit-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
.falseStat {
  border-radius: 15px !important;
  margin-top: 15px;
  margin: 1rem 0rem;
}
.answeredCard {
  background-color: var(--grey_300);
  color: var(--dark);
  border: none;
  border-radius: 15px !important;
  padding: 1rem 1.25rem;
  letter-spacing: -0.18px;
  font-size: 18px;
}

.focusCard {
  background-color: var(--white_100);
  border-radius: 15px !important;
  padding: 1rem 1.25rem;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  letter-spacing: -0.2px;
  font-size: 20px;
  -webkit-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}

.highlight-unanswered-card {
  -webkit-box-shadow: 0px 0px 0px 2px rgb(238, 59, 59);
  box-shadow: 0px 0px 0px 2px rgb(238, 59, 59);
}

.unansweredCard {
  background-color: var(--white_200);
  border-radius: 15px !important;
  padding: 1rem 1.25rem;
  border: 2px solid var(--white_300);
  font-size: 18px;
  letter-spacing: -0.18px;
}
.hhSubAnswerWrpr,
.lfSubAnswerWrpr {
  background-color: var(--grey_400);
  color: var(--dark);
  border: none;
  padding: 7% 1.25rem 1rem 1.25rem;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -7%;
  border-radius: 15px;
}
.lfSubAnswerWrpr {
  padding: 5% 1.25rem 1rem 1.25rem !important;
}
.cursorPointer {
  cursor: pointer;
}
.circularProgressbarStyle {
  width: 19px;
  height: 19px;
}
.activeIcon {
  color: var(--green);
}
.notActiveIcon {
  color: var(--grey_700);
}
.darkMenuText {
  color: var(--dark);
}
.lightMenuText {
  color: var(--grey_800);
}
.assessmentQuestionsCard {
  height: 1200px;
}
.assessmentSpinner {
  width: 1rem;
  height: 1rem;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
}
.papSpinner{
  width: 0.6rem;
  height: 0.6rem;
  border: 0.1em solid currentColor;
  border-right-color: transparent;
  vertical-align: middle;
}
.therapySpinner {
  width: 4rem;
  height: 4rem;
}
.question {
  font-weight: 400;
}
.responsed {
  color: var(--grey_1000);
}
.SubmitAnswerBtn {
  background-color: var(--green);
  border-radius: 25px;
  color: var(--white_200);
  width: 100px;
}
.navbar-toggler {
  outline: none !important;
  box-shadow: none !important;
}
/* @font-face {
  font-family: "GoogleSans-Regular";
  src: local("GoogleSans-Regular"),
    url("../assets/fonts/Sans.woff") format("woff");
} */
/* @font-face {
  font-family: "GoogleSans-Regular";
  src: local("GoogleSans-Regular"),
    url("../assets/fonts/ProductSans-Regular.woff") format("woff");
} */

.bgColor {
  background-color: aliceblue !important;
}
.card-header {
  /* background-color: #fafafa; */
  margin-bottom: 0px !important;
}
.nav-link {
  padding: 0rem 0rem;
  display: inline-flex;
  color: #151852;
  cursor: pointer;
  font-size: 17px;
  font-weight: 400!important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: var(--white_200);
}
.leftMenu span {
  font-size: 18px;
}
.leftMenu img {
  height: 18px;
  width: 18px;
}
.footer hr {
  width: 60%;
}
.fMute {
  color: var(--grey) !important;
}
.footerUl h4 {
  font-size: 18px !important;
  color: var(--dark) !important;
}

.social-media a,
.phone-number a {
  color: var(--dark);
}

.nextPrevBtnDiv button {
  border-radius: 25px;
  width: 105px;
  box-shadow: none !important;
  padding: 10px;
  z-index: 0;
}
.prevBtn {
  background-color: #f0f0f0;
}
.CompleteAssessmentBtn {
  background-color: var(--green);
  color: var(--white_100);
}
.CompleteAssessmentBtnAfter {
  background-color: var(--green);
  color: var(--white_100);
  width: 125px !important;
}
.nextBtnDisabled {
  background-color: #e6e6e6;
}
.nextBtnEnabled {
  background-color: var(--green);
  color: var(--white);
}

small {
  /* background: #2dbdc6; */
  mix-blend-mode: normal;
  opacity: 1;
  font-size: 65%;
}
.footerUl {
  list-style-type: none;
}
.footerUl li {
  font-size: 16px;
  color: var(--dark);
  margin: 0;
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 10px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.9rem;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.9rem;
  overflow: hidden;
  color: var(--white_100);
  text-align: center;
  white-space: nowrap;
  background-color: var(--green);
  transition: width 0.9s ease;
}

@media screen and (max-width: 600px) {
  .footerColumn {
    width: 100%;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: var(--green) !important;
  border-radius: 10px;
  top: 0;
}

.main-container {
  max-width: 1440px;
  width: 85%;
  margin: auto;
}
.assessmentCard {
  margin-top: 50px;
  margin-bottom: 100px;
  border-radius: 15px;
  background-color: var(--white_200);
  z-index: 0;
}
.asmntHstrclps,
.asmntHstrclps .rounded {
  border-radius: 10px !important;
}
.asmntHstrclps {
  background-color: #f7f7f7;
  cursor: pointer;
}
.assessment-date {
  flex-basis: 60%;
}
.historyModal {
  padding: 1vw 2vw;
  background: #33ca8f;
  color: #ffffff;
  align-items: center;
}
.modalTitle {
  color: white;
}
.modal-details {
  background: #eaeaea;
  color: #9d9d9d;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}
.svg {
  fill: #33ca8f;
}
.css-f47iob {
  left: 0;
}
.tour-mask {
  opacity: .5;
}