.form {
  margin: 0 40px;
}

.form-component {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-component-button {
  display: flex;
  justify-content: center;
}

.button {
  padding: 6px;
  border: none;
  background-color: #67d2c9;
  border-radius: 12px;
  color: #f9f9f9;
  box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
}

.button:hover {
  color: #f8e8e8;
  background-color: #29bdb0;
}

.button:focus {
  outline: none;
  box-shadow: 0px 8px 15px rgb(0 0 0 / 20%);
}

.error-message {
  text-align: center;
  font-size: 25px;
  margin: 10px 0;
  color: #d8000c;
  background-color: #ffbaba;
}

.success-message {
  text-align: center;
  font-size: 25px;
  margin: 10px 0;
  color: #4f8a10;
  background-color: #dff2bf;
}
