.field-wrapper {
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-column-gap: 70px;
  margin: 6px 0;
  font-size: 16px;
}
.bottom-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.illustration-notification {
  width: 22vh;
  @media (max-width: 580px) {
    width: 12vh;
  }
}
.container {
  @media (max-width: 640px) {
    padding-left: 0;
    padding-right: 0;
  }
}
.flex-1 {
  flex: 1;
}
.day-button {
  background: #fff;
  padding: 10px 16px;
  &__active {
    background: linear-gradient(180deg, #33ca8f 0%, #0bb8c8 100%);
  }
  @media (max-width: 580px) {
    padding: 5px 8px;
  }
}

.circle-switcher {
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 100%;
  margin-left: 3px;
  transition: all 0.3s;

  &__active {
    margin-left: 28px;
  }
  @media (max-width: 747px) {
    width: 16px;
    height: 16px;
  }
}

.switcher-wrapper {
  transition: all 0.1s;
  border: 0;
  outline: none;
  border-radius: 50%;
  padding: 2px;
  border-radius: 24px;
  width: 56px;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #ccc;

  &__active {
    background: #33ca8f;
  }
  @media (max-width: 747px) {
    width: 50px;
    height: 24px;
  }
}

.days-wrapper {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  > button {
    flex: 1 1 auto;
    border: 1px solid rgb(184, 184, 184);
    cursor: pointer;
    @media (max-width: 640px) {
      font-size: 15px;
    }
  }
}
.modal-form-wrapper {
  position: relative;
}
.form-input-wrapper {
  position: relative;
  // height: 60px;
  // width: 98%;
  // left: 20px;
  // @media (max-width: 640px) {
  //   width: 98%;
  //   left: 0;
  // }

  .label {
    position: absolute;
    z-index: 2;
    color: #757575;
    transition: all 0.2s linear;
    font-size: 1rem;

    &__active {
      color: #4285f4;
      background: aliceblue;
      transform: translatey(-12px) translatex(-6px) scale(0.8);
    }

    &__active-text {
      color: #4285f4;
      transform: translatey(-16px) translatex(-13px) scale(0.8);
    }
  }

  input {
    z-index: 1;
    background-color: white;
    position: relative;
    width: 100%;
    height: 45px;
    border-radius: 4px;
    padding: 0 2rem;
    margin: 0;
    outline: 0;
    border: 1px solid #ced4da;
    transition: all 0.2s;
  }

  .input__active {
    border-bottom: 2px solid #4285f4;
  }
}
.modal-scroll {
  overflow-y: scroll;
  margin: 20px 0 0;
  >* {
    z-index: 2;
    position: relative;
  }
}
.modal-form {
  // min-width: 15em;
  // // box-shadow: 1px 7px 20px 2px rgba(0, 0, 0, 0.4);
  // background-color: #fff;
  // border-radius: 5px;
  // margin: auto;
  // display: flex;
  // flex-direction: column;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  // max-height: 90vh;
  // padding: 20px;
  // position: relative;
  // width: 720;
  // // height:658;
  // border-radius: 12px;

  background-color: #fff;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  // width: 720;
  border-radius: 12px;
  // height: 100%;
  padding: 20px 30px 35px;
  max-height: 100%;
  width: 100%;

  &-mobile {
    width: unset;
    max-width: 660px;
    p,
    h2,
    a {
      margin: 0.6rem 0;
    }
  }
  > * {
    z-index: 2;
  }
  @media (max-width: 747px) {
    width: auto;
    margin: 0.5rem;
    padding: 20px 16px 30px;
  }

  

  &-loading {
    filter: blur(1px);
  }
  form {
    position: relative;
    button {
      position: absolute;
      right: 0;
      height: 100%;
      top: 0;
    }
  }

}

.modal {
  font-family: "CircularStdBook";
}
.modal-title {
  
  font-size: 30px;
  line-height: 1;
  @media (max-width: 580px) {
    font-size: 25px;
    line-height: 0.7;
  }
}

.text-left {
  text-align: "left";
}
.modal-description {
  
  line-height: 20px;
  font-size: 18px;
  text-align: center;
  margin-top: 12px;
  @media (max-width: 580px) {
    line-height: 16px;
    font-size: 15px;
  }
  @media (max-width: 480px) {
    line-height: 13px;
    font-size: 13px;
  }
}
.fbn-logo-self-harm {
  // position: absolute;
  // top: 20px;
  // left: 30px;
  @media (max-width: 747px) {
    // left: 16px;
    // width: 170px;
    // margin-top: -26px !important;
  }
}

.downloadapp-container {
  text-align: left;
  width: 720px;
  @media (max-width: 640px) {
    width: 90vw;
  }
}
.download-title {
  @media (max-width: 640px) {
    font-size: 36px !important;
  }
}
.download-desc {
  margin-top: 1.85rem;
  @media (max-width: 640px) {
    font-size: 18px !important;
  }
}
.download-subtitle {
  @media (max-width: 640px) {
    font-size: 18px !important;
  }
}
.modal-header {
  position: relative;
  padding: 0;
}
.modal-wrapper {
  display: flex;
  // align-items: center;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0%;
  left: 0;
  z-index: 1300;
  font-family: "CircularStdBook", Arial, Helvetica, sans-serif;
  overflow-y: scroll;
  // @media (max-width: 747px) {
  //   height: 90vh;
  // }
  > div {
    height: calc(100% - 3.5rem);
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    margin: 1.75rem auto;
  }
}

.modal-body-wrapper {
  background-image: linear-gradient(
      rgba(179, 94, 94, 0),
      rgba(253, 253, 253, 100)
    ),
    url("../assets/images/illustration-notification-scheduler.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.input-not-active {
  height: 100%;
  margin: 0;
  padding: 10px 20px;
  pointer-events: none;
}

.modal-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: "unset";
  opacity: 0.5;
  z-index: 1200;
  max-width: 100%;
  left: 0;
  top: 0;
  background: black;
}

.select-wrapper {
  width: 95px;
  position: relative;

  .toggle-button {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    position: relative;
    z-index: 2;

    background: #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px;
  }

  button {
    width: 100%;
    border: 0;
    outline: none;
    background-color: #fff;
    padding: 0.25rem 0.5rem;
    text-align: initial;
  }
}

.toggleable-weeks-options {
  top: 100%;
  position: absolute;
  height: 0px;
  overflow: hidden;
  transform: translateY(-2px);
  width: calc(100% + 2px);
  left: -1px;
  z-index: 1;

  &__active {
    height: auto;
    border: 1px solid #ced4da;
  }

  div {
    padding: 4px;
    background-color: #fff;
    &:hover {
      background-color: #0078d7;
      color: #fff;
    }
  }
}

.weeks-per-month-field {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0;
  font-size: 18px;
}

.mobile-link {
  font-weight: 500;
  color: #151852;
  text-decoration: underline;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;

  &-center {
    align-self: center;
    text-align: center;
  }
}

.submit-btn {
  align-self: center;
  padding: 6px 20px !important;
 border: none;
  background: linear-gradient(180deg, #33ca8f 0%, #0bb8c8 100%);
  box-shadow: 0px 8px 18px rgba(51, 202, 143, 0.18);
  border-radius: 4px;
  font-size: 18px;
}

.notification-loading-cover {
  cursor: wait;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.11);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: initial !important;
  left: 0;
  top: 0;
}
